<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
      :mark="mark"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`行业背景和业务痛点`"
      :en_title="`Industry Background & Business Pain Points`"
    ></Paragraph>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`平台特色与优势`"
      :en_title="`Platform Features & Advantages`"
    ></Paragraph>
    <Logotitlebox :datalist="datalistx1"></Logotitlebox>
    <Logotitlebox :datalist="datalistx2"></Logotitlebox>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`应用场景及案例`"
      :en_title="`Application Scenarios & Cases`"
    ></Paragraph>
    <div
      style="
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 3rem;
      "
    >
      <img
        :src="imageurl"
        style="width: 50%; height: 100%; align-items: center"
      />
    </div>
    <Logotitleboxwtc :datalist="datalist4"></Logotitleboxwtc>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`服务内容`"
      :en_title="`Service Content`"
    ></Paragraph>
    <Titlestrlist style="margin-left: 15%" :datalist="datalist1"></Titlestrlist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Logotitleboxwtc from '@/components/chain/basic/common/logotitleboxwtc';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Lettertitleblock,
    Logotitleboxwtc,
    Titlestrlist,
    Logotitlebox,
  },
  data() {
    return {
      title: '保交链服务',
      body:
        '微软研究实验室最新开发了一项人工智能技术，可以从类似字幕的文本描述中生成图像，更关键的是，它被编程为尤其关注单个词汇。'
        + '该研究论文中注明，一项行业标准测试结果显示，这种有意识地“聚焦”让“这种技术”在图像质量方面比之前最先进的从文本到图像的生成技术提升了将近三倍。',
      image: require('@/assets/image/chain/alliance/iec/iec.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/alliance/iec/21_01.jpg'),
        require('@/assets/image/chain/alliance/iec/21_02.jpg'),
        require('@/assets/image/chain/alliance/iec/21_03.jpg'),
        require('@/assets/image/chain/alliance/iec/21_04.jpg'),
      ],
      mark: '1',
      datalistl: [
        {
          title: '数据可得性',
          imgsrc: 'A',
          list: [
            '由于传统保险固有的“道德风险”与“逆向选择”问题，保险公司为了对保险进行勘察往往需要建立强大的核保部门或者依靠公估等第三方机构。',
          ],
        },
        {
          title: '数据连续性',
          imgsrc: 'B',
          list: [
            '用户数据存储在不同行业、不同主体数据系统，数据分散在数据孤岛且不共享',
          ],
        },
        {
          title: '特殊风险',
          imgsrc: 'C',
          list: [
            '艺术品、宝石等保险标的因缺乏有效评估办法，难以查询标的来源和流通记录，评估难度大误差高。',
          ],
        },
        {
          title: '减少欺诈',
          imgsrc: 'D',
          list: ['信息不共享造成的保险欺诈，对保险公司造成巨大损失。'],
        },
        {
          title: '保险代理',
          imgsrc: 'E',
          list: ['中介代理成本高，结算不及时，中介造假时有发生。'],
        },
        {
          title: '隐私保护',
          imgsrc: 'F',
          list: [
            '传统保险核保核赔过程中，经常涉及验核用户的敏感信息，这类信息因国家法规和公司内部管控原因往往分散留存，难以实现核保核赔和反欺诈的协同。',
          ],
        },
      ],
      datalistx1: [
        {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          title: '应用开发',
          list: [
            '提供便捷高效的应用开发方式',
            '通过应用程序编程接口（API）或',
            '功能分离的软件开发工具包（SDK）',
            '满足开发者在应用开发、系统管理及',
            '系统运维的需求',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/ding.svg'),
          title: '运维监控',
          list: [
            '完善的监控系统、交易、',
            '网络、CPU、内存及存储',
            '全面关注区块链网络健康状况',
            '实现系统层及应用层实时预警功能',
            '完整的区块链升级方案',
            '全面支持业务不间断情况下实现区块链底层及应用的升级',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/db.svg'),
          title: '多链架构',
          list: [
            '底层架构均衡考虑系统',
            '性能、安全、可靠性及可扩展性',
            '引入“通道”概念',
            '实现不同业务的数据隔离及访问权限控制，',
            '提供丰富的智能合约模板',
            '可支持一次底层部署多链运行',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '性能可靠',
          list: [
            '通过性能优化、配置参数调整及',
            '高效的应用设计',
            '可以达到企业级应用的性能要求',
            '如在数字保单存证场景',
            '高并发支持每秒5万笔保单哈希数据',
            '的上链处理',
          ],
        },
      ],
      datalistx2: [
        {
          imageurl: require('@/assets/image/contentsvg/card.svg'),
          title: '安全体系',
          list: [
            '底层内嵌经过认证的国密算法',
            '完全满足国家金融系统的安全要求',
            '同时支持国际商用密码算法',
            '满足国际化业务的安全要求',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '监管审计',
          list: [
            '提供监管CA配置模块',
            '满足监管方监管审计要求',
            '满足业务方合规要求',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/car.svg'),
          title: '灵活部署',
          list: [
            '区块链节点可按照企业要求实现企业本地部署及保交所云平台托管部署的两种部署模式',
          ],
        },
      ],
      datalist1: [
        {
          title: '> ',
          content:
            '为保险行业提供区块链基础设施',
        },
        {
          title: '> ',
          content: '提高行业服务效率和服务水平、降低运营成本；',
        },
        {
          title: '> ',
          content: '打造保险行业互联互通新格局；',
        },
        {
          title: '> ',
          content: '引领行业科技进步与创新发展；',
        },
        {
          title: '> ',
          content: '构建稳定、高效、安全、可信的行业发展生态。',
        },
      ],
      imageurl: require('@/assets/image/chain/alliance/iec/iec.png'),
      datalist4: [
        {
          // imageurl: require('@/assets/image/contentsvg/tikit.svg'),
          title: '保交所区块链数字保单',
          list: [
            '从根本上解决保单电子化以后的信任问题。',
            '在整个托管和验真过程中，实现减少纠纷，减少摩擦。',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          // imageurl: require('@/assets/image/contentsvg/del.svg'),
          title: '保险链技术支持的保单质押平台',
          list: [
            '利用区块链技术构建的保单质押平台，质押的保单将更为安全；',
            '质押流程将更为高效；保险业的流动资金将更少地被占用。',
            '充分保障了保单质押流程的公正和公开',
            '&nbsp;',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
